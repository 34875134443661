<template>
    <div class="LegalNotices">
        <!-- <h1>法律声明</h1> -->
        <img src="https://img.youstarplanet.com/website/legalNotices/LegalNotices.png" alt="">
        <div class="navigation" @click="CaseShow"><span>首页 > 法律声明</span></div>
        <div class="content">
            <div>版权声明</div>
            <p>
                星都时代 对其发行的或与合作伙伴共同发行的作品享有版权，受各国版权法及国际版权公约的保护。<br>
                对于上述版权内容，超越合理使用范畴、并未经本公司书面使用行为，我公司均保留追究法律责任的权利。<br>
            </p>
            <div>商标声明</div>
            <p>
                星都时代对“星都时代”等文字或形象均进行了商标注册保护，星都时代的注册商标信息，可见诸于各国公开的商标注册信息中。<br>
                未经星都时代的许可而使用上述商标，可能会侵犯星都时代的注册商标权，对于涉嫌侵犯星都时代注册商标权的行为，我公司将保留追究法律责任的权利。<br>
            </p>
            <div>相关法律</div>
            <p>
                《中华人民共和国刑法》(节录)<br>
                《中华人民共和国商标法》<br>
                《全国人民代表大会常务委员会关于维护互联网安全的决定》<br>
                《计算机信息网络国际联网安全保护管理办法》<br>
                《计算机软件保护条例》<br>
            </p>
            <div>网站标识</div>
            <p>
                www.youstarplanet.com、www.ysp.com等皆为星都时代网站域名，用户欲了解星都时代网站的备案详情，请点击网站上的工商及网安标志：<br>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602006653" target="_blank"><img src="https://img.youstarplanet.com/website/bottom_logo1.png" alt=""></a>
                <a href="https://beian.miit.gov.cn" target="_blank"><img src="https://img.youstarplanet.com/website/bottom_logo2.png" alt=""></a><br>
                本公司郑重提醒广大用户，切勿登陆访问仿冒星都时代的不法网站，以免上当受害。<br>
            </p>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return { }
    },
    methods: {
        CaseShow(){
            this.$router.push({path:`/`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.LegalNotices{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 68px auto 200px;
        padding: 0 80px;
        div{
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 40px;
        }
        &>p{
            font-size: 16px;
            line-height: 35px;
            margin-left: 24px;
            margin-bottom: 76px;
        }
    }
}
</style>